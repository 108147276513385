import React, { useContext, useEffect } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { ThemeProvider } from "styled-components"
import { theme } from "../theme"
import { BrinkContext } from "../components/context/BrinkContext"
import queryString from "query-string"
import Loader from "../components/ui/Loader"

const Router = ({ pageContext }) => {
  const location = useLocation()
  const { ADYEN_ENVIRONMENT, ADYEN_CLIENT_KEY, closeCart } = useContext(BrinkContext)

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  const handleResult = (result, signature, orderId) => {
    console.log("Adyen details result", result)
    const resultCode = result.resultCode
    if (resultCode === "Cancelled" || resultCode === "Refused" || resultCode === "Error") {
      navigate("/checkout/", {
        state: {
          error: resultCode === "Cancelled" ? "canceled" : "payment-failure"
        }
      })
    } else if (resultCode.toLowerCase() === "authorised") {
      navigate("/success/", {
        state: { signature, orderId, resultCode }
      })
      closeCart()
    } else {
      navigate("/")
    }
  }

  useMountEffect(() => {
    const handleRedirectResult = async (sessionId, redirectResult, signature, orderId) => {
      const configuration = {
        clientKey: ADYEN_CLIENT_KEY,
        environment: ADYEN_ENVIRONMENT,
        session: {
          id: sessionId
        },
        onPaymentCompleted: (result, _) => {
          handleResult(result, signature, orderId)
        }
      }

      import("@adyen/adyen-web")
        .then(({ default: AdyenCheckout }) => AdyenCheckout)
        .then((AdyenCheckout) => AdyenCheckout(configuration))
        .then((checkout) => {
          checkout.submitDetails({ details: { redirectResult } })
        })
    }
    const { sessionId, redirectResult, signature, orderId } = queryString.parse(location.search)
    handleRedirectResult(sessionId, redirectResult, signature, orderId)
  })

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <Loader isLoading={true} />
    </ThemeProvider>
  )
}

export default Router
